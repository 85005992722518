<template>
<v-col style="padding-bottom:100px;">
  <v-row>
    <v-col cols="12" sm="11" md="10" lg="8" class="mx-auto header-section">
      <v-row>

        <v-col cols="12"  class="text-center mx-auto header-section">
          <router-link to="/game/battle/menu" style="text-decoration:none;">
            <h2 class="mt-1 ml-4 primary--text">Bataille navale</h2>
          </router-link>
          <h3 class="mt-1 ml-4 primary--text text--darken-2">~ ~ ~<br>Nouvelle partie</h3>
        </v-col>
        <v-col cols="12" sm="9" md="10" lg="8" class="text-center mt-5 mx-auto header-section">
          <v-btn color="primary" outlined class="mt-5" block text @click="showDialogRules=true">Lire les règles</v-btn>
        </v-col>


        <v-col cols="12" md="10" lg="9" style="border-top:0px solid #fff;margin-top:30px;" class="mx-auto mt-5 pa-5">
          
          <h4 class="font-papyrus mt-5 mb-0">
            <b><v-icon class="mb-1">mdi-account</v-icon> Mode de jeu : </b> Solo ou multijoueur ?
          </h4>
          <small v-if="!$store.state.auth.isLogin" class="d-block">
            <i>Vous devez être inscrit, et devenir <b>membre d'équipage</b> pour jouer en multijoueur</i>
          </small>
          <small v-else-if="!$store.state.auth.user.planPaid" class="d-block">
            <i>Vous devez être <b>membre d'équipage</b> pour jouer en multijoueur</i>
          </small>
        
          <v-row class="mt-0">
            <v-col cols="12" sm="6" class="text-center px-5">
              <v-btn block class="font-papyrus" x-large :outlined="gameMode!='solo'" 
                      color="teal darken-3" elevation="0"
                      @click="gameMode='solo'">Jouer en solo
              </v-btn>
              <span class="teal--text">Partez à la chasse aux vaisseaux fantômes</span>
            </v-col>
            <v-col cols="12" sm="6" class="text-center">
              <v-btn block class="font-papyrus" x-large :outlined="gameMode!='multi'" 
                      color="deep-purple darken-2" elevation="0" @click="gameMode='multi'"
                      :disabled="!$store.state.auth.user.planPaid"
                      :title="!$store.state.auth.user.planPaid ? 'Vous devez être membre d\'équipage pour jouer en multijoueur !' : ''" >
                      Jouer en multijoueur
              </v-btn>
              <span class="deep-purple--text">Défiez un autre pirate</span>
            </v-col>
            
          </v-row>
        </v-col>

        
        <v-col cols="11" md="5" v-if="$store.state.auth.isLogin"  class="mx-auto"
               style="border:1px dashed #fbc02d;margin-top:30px;">
          <h4 class="font-papyrus mt-1">
            <b><v-icon color="primary" class="">mdi-hand-coin</v-icon> Mise  </b>
          </h4>
          <v-divider class="my-3"/>
          <h4 class="font-papyrus">
            Combien souhaitez-vous miser ?
          </h4>
          <small v-if="gameMode=='solo'">
            Le Trésor Public s'engage à vous verser votre mise en cas de victoire.
            <br>
            En cas de défaite, votre mise est versée au Trésor Public.
            </small> 
          <small v-if="gameMode=='multi'">Les deux joueurs misent la même somme.<br>Le vainqueur remporte la mise adverse.</small> 
          <div class="d-block text-center my-5">
            <v-img src="../../../assets/img/monnaie_pirate_logo.png" 
                   class="d-inline-block" max-height="100" width="100" contain
                   style="margin-bottom:-50px;margin-right:15px;margin-top:20px!important;"/>
              <div class="d-inline">
                <span class="primary--text font2x font-led">
                  <b class="mr-1">{{ amountBet }}</b> {{ $store.state.app.monyUnity }}
                </span>
                <br>
                <span style="margin-left:60px;" class="grey--text">~ {{ monyToEuro(amountBet) }} €</span>
              </div>
            <br><br>
          </div>
          <v-slider v-model="amountBet" :step="amountBetMin" :min="amountBetMin" :max="amountBetMax">
            <template v-slot:prepend>
              <v-icon @click="amountBet-=amountBetMin">mdi-minus</v-icon>
            </template>

            <template v-slot:append>
              <v-icon @click="amountBet+=amountBetMin">mdi-plus</v-icon>
            </template>
          </v-slider>
        </v-col>
        
        <v-col cols="11" md="5" style="border:1px dashed #2196F3;margin-top:30px;" class=" mx-auto">
          <h4 class="font-papyrus mt-1">
            <b><v-icon color="blue" class="">mdi-sail-boat</v-icon> Bateaux</b>
          </h4>
          <v-divider class="my-3"/>
          <h4 class="font-papyrus">
            Jouer avec combien de bateau(x) ?
          </h4>
          <small>La partie se termine quand tous les bateaux<br>d'un même joueur sont coulés.</small> 
          <div class="d-block text-center my-5 pb-2 pt-5 pl-3">
            <v-icon style="font-size:4.8em;" class="mt-4 mb-3 mr-5 ml-4 pl-4" color="blue">mdi-sail-boat</v-icon>
            <div class="d-inline-block blue--text font2x font-papyrus ">
              <b class="mr-1">{{ nbBoat }}</b> bateau{{ nbBoat > 1 ? "x" : "" }}
            </div>
          </div>
          <v-slider v-model="nbBoat" :min="nbBoatMin" :max="nbBoatMax" color="blue">
            <template v-slot:prepend>
              <v-icon @click="nbBoat--">mdi-minus</v-icon>
            </template>

            <template v-slot:append>
              <v-icon @click="nbBoat++">mdi-plus</v-icon>
            </template>
          </v-slider>
        </v-col>


      </v-row>

        <v-col cols="12" md="7" style="border-top:0px dashed #fbc02d;margin-top:40px;margin-bottom:60px;" class="mx-auto text-center">
          <v-btn @click="createGame" block rounded class="font-papyrus" x-large color="green darken-2" elevation="0">
            <v-icon class="ml-2">mdi-check</v-icon>
            Commencer la partie <v-icon class="ml-2">mdi-arrow-right-circle</v-icon>
          </v-btn>
        </v-col>
    </v-col>
  </v-row>


  <v-dialog v-model="showDialogRules" max-width="650" :fullscreen="$vuetify.breakpoint.width < 600" scrollable>
    <v-card class="pa-0" color="transparent">
      
      <v-toolbar color="transparent" elevation="0">
        <h2 class="font-papyrus primary--text">
          Règles du jeu
        </h2>
        <v-spacer/>
        <v-btn fab x-small elevation="0" @click="showDialogRules=false">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider class="mb-5"/>

      <v-card-text>
        <v-card-title>
          <v-icon class="mb-2 mx-1" color="primary">mdi-pirate</v-icon> 
          <b class="primary--text font-papyrus">VS</b> 
          <v-icon class="mb-2 mx-1" color="primary">mdi-robot</v-icon> | Partie solo
        </v-card-title>

        <v-divider class="mb-5"/>

        <p class="font-papyrus">
          Partez à la chasse aux vaisseaux fantômes, et tentez de libérer les Bermudes de la malédiction.
          Nous vous paierons pour ça ! Votre prix sera le nôtre !
          <br>Mais si vous échouez, la mise est pour nous !
        </p>

        <ul class="rules">
          <li> Lors d'une partie solo, vous jouez contre l'ordinateur.
          </li>
          <li> Vous décidez de la somme en PRT que vous souhaitez miser, entre 0.01 et 1 PRT.
          </li>
          <li> Vous décidez aussi du nombre de bateaux avec lesquels vous voulez jouer.
          </li>
          <li> Chaque joueur joue l'un après l'autre, un coup à la fois.
          </li>
          <li> L'ordinateur joue automatiquement 3 secondes après chacun de vos coups. 
            Et il ne triche pas ! Il joue au hasard, dans les mêmes conditions que vous, sans connaître vos positions à l'avance.
          </li>
          <li> Vous avez la possibilité de tirer directement, ou bien d'utiliser le radar pour essayer de repérer les bateaux ennemis.
          </li>
          <li> La mise est transférée dès le début de la partie sur un compte sécurisé (le "Coffre des jeux").
          </li>
          <li> Si vous remportez la partie, vous récupérez votre mise depuis le Coffre des Jeux, 
            et le Trésor Public vous versera lui aussi le montant de votre mise en récompense pour service rendu à l'humanité.
          </li>
          <li> Si perdez la partie, votre mise sera envoyée directement au Trésor Public.
          </li>
          <li> Vous pouvez quitter et reprendre la partie à tout moment.
          </li>
        </ul>

        <v-divider class="my-5"/>

        <v-card-title>
          <v-icon class="mb-2 mx-1" color="primary">mdi-pirate</v-icon>
          <b class="primary--text font-papyrus">VS</b> 
          <v-icon class="mb-2 mx-1" color="primary">mdi-pirate</v-icon> | Partie multijoueur
        </v-card-title>

        <v-divider class="mb-5"/>

        <p class="font-papyrus">
          Les <b>membres d'équipages</b> peuvent organiser des batailles entre-eux pour s'amuser s'ils le souhaitent, 
          à condition de ne pas attaquer les navires du Monde des Vivants.
        </p>
        <ul class="rules">
          <li> Lors d'une partie multijoueur, vous jouez contre un autre joueur.
          </li>
          <li> Le créateur de la partie décide de la somme en PRT qu'il souhaite miser, entre 0.1 et 10 PRT.
          </li>
          <li> Il décide aussi du nombre de bateaux avec lequels chaque joueur devra jouer.
          </li>
          <li> Si vous rejoignez une partie créée par un autre joueur, vous devez disposer de la quantité suffisante de PRT pour jouer la mise définie par le créateur.
          </li>
          <li> Chaque joueur joue l'un après l'autre, un coup à la fois.
          </li>
          <li> Vous avez la possibilité de tirer directement, ou bien d'utiliser le radar pour essayer de repérer les bateaux ennemis.
          </li>
          <li> La mise est transférée dès le début de la partie sur un compte sécurisé (le "Coffre des jeux").
          </li>
          <li> Si vous remportez la partie, vous récupérez votre mise depuis le Coffre des Jeux, et vous remportez la mise de votre adversaire.
          </li>
          <li> Vous avez 48h pour terminer la partie. Au delà de 48h, le dernier joueur à avoir joué remporte la partie.
          </li>
        </ul>
      </v-card-text>

      <v-toolbar color="transparent" elevation="0">
        <v-spacer/>
        <v-btn small elevation="0" @click="showDialogRules=false">
          <v-icon small color="">mdi-close</v-icon> Fermer
        </v-btn>
      </v-toolbar>

    </v-card>
  </v-dialog>

  
</v-col>
</template>

<style>
  .rules li{
    padding: 5px 0;
  }
</style>

<script>

import axios from 'axios'
import router from '../../../router/router'
//import core from '../../../plugins/core.js'

//import Post from '@/components/Post.vue'
const config = require('../../../config/' + process.env.NODE_ENV)

export default {
  name: 'empty',
  components: {  },
  data: () => ({
    gameMode: 'solo',
    amountBet: 1,
    // amountBetMin: 1,
    // amountBetMax: 10,
    nbBoat: 3,
    nbBoatMin: 1,
    nbBoatMax: 4,

    showDialogRules: false,
    
  }),
  async mounted(){
    this.$store.dispatch('app/incStat', '/game/battle/create')
    this.amountBet = this.amountBetMin * 5
  },
  methods: {
    createGame(){
      //this.gameMode = mode
      let params = {
        amountBet: this.amountBet,
        nbBoat: this.nbBoat,
        gameMode: this.gameMode
      }
      
      axios.post('/game/battle/create', params)
           .then((res)=>{
            if(res.data.error == false && res.data.gameId != null){
              this.$store.dispatch('auth/refreshUserData')
              router.push('/game/battle/play/'+res.data.gameId)
            }
           })

    },
    monyToEuro(amount){ return (amount * config.monyToEuro).toFixed(0) },

  },
  computed: {
    amountBetMin(){
      return (this.gameMode == "solo") ? 0.01 : 0.1
    },
    amountBetMax(){
      return (this.gameMode == "solo") ? 1 : 10
    }
    
  }
}
</script>
